.tag {
  height: 30px;
  min-height: 30px;
  width: fit-content;
  padding: 0 8px 0 12px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;

  &--black {
    background-color: var(--black);
    color: var(--white);

    .tag__icon-container {
      background-color: var(--gray-100);
    }
  }

  &--gray {
    background-color: var(--gray-20);
    color: var(--black);

    .tag__icon-container {
      background-color: var(--black);
    }
  }

  .tag__icon-container {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    cursor: pointer;
    color: var(--white);
  }
}
