.shipment-option {

  &__description {
    width: 100%;
    color: var(--gray-100);
    font-size: 12px;
    
    p {
      width: calc(100% - 52px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--expanded {
      max-height: fit-content;
      overflow: visible;

     
    }
  }

  &__description-container {

    &--expanded {
      max-height: unset;

      .shipment-option__description {
        cursor: default;
        
        p {
          white-space: normal;
          overflow: visible;
          text-overflow: unset;
        }
      }
    }
  }
}