
.toast-success,
.toast-error,
.toast-info {
  border-radius: 8px !important;
  padding: 8px 12px !important;
  box-shadow: none !important;
  border: none !important;
}

.toast-container .ngx-toastr {
  width: fit-content !important;

  .toast-title,
  .toast-message {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    white-space: nowrap;
  }
}

.toast-success {
  background: var(--malachite-100);
}

.toast-error {
  background: var(--scarlet);
}

.toast-success,
.toast-error {
  color: var(--white) !important;

  * {
    color: var(--white) !important
  }
}

.toast-info {
  background:  var(--black) !important;
  color: var(--white) !important;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: 100px !important;
  margin-bottom: 70px !important;
  padding: 0 52px 0 32px !important;

  .toast-close-button {
    position: absolute;
    right: 20px;
    top: auto;
  }

  * {
    color: var(--white) !important;
  }
}

@media screen and (max-width: 640px) {
  .toast-success,
  .toast-error,
  .toast-info {
    padding: 8px;
  }

  .toast-container {
    top: 0 !important;
    right: 0 !important;

    .ngx-toastr {
      width: 100vw !important;

      .toast-title,
      .toast-message {
        font-size: 12px;
        line-height: 20px;
        white-space: pre-wrap;
      }
    }
  }
}
