.file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px;
  background: var(--gray-10);
  border: 2px dashed var(--gray-40);
  border-radius: 8px;
  transition: .2s ease-in-out;

  img {
    margin-bottom: 24px;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:hover {
    border-color: var(--black);
    transition: .2s ease-in-out;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--gray-100);

    &--secondary {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: var(--gray-70);
    }
  }

  &__button {
    margin-left: 4px;
    text-decoration: underline;
    font-weight: 700;
    color: var(--black);
  }
}

.image-item {
  width: 214px;

  &__picture-container {
    position: relative;
    width: 214px;
    height: 178px;
    margin-bottom: 8px;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    transition: .2s ease-out;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 214px;
      height: 178px;
      object-fit: contain;
    }

    &--selected,
    &:hover {
      border-color: #1F1F1F;
      transition: .2s ease-out;
    }
  }

  &__selected-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: default;
  }

  &__main-image-label {
    position: absolute;
    bottom: 12px;
    right: 10px;
    padding: 2px 8px;
    background: #000;
    border-radius: 100px;
    color: var(--white);
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
  }

  &__image-type-label {
    position: absolute;
    bottom: 12px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 100px;
    color: var(--black);
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    background: var(--light-green-40);

    &--temporary {
      background: var(--yellow-40);
    }
  }

  &__more-section {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--gray-10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__context-menu {
    position: absolute;
    bottom: -64px;
    right: 0;
    z-index: 100;
    padding: 6px 0;
    background: #000;
    border-radius: 8px;

    &--temporary {
      bottom: -38px;
    }
  }

  &__context-menu-item {
    padding: 4px 10px;
    color: var(--white);
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    white-space: nowrap;
    cursor: pointer;
    transition: .2s ease-out;

    &:hover {
      background-color: #fff3;
      transition: .2s ease-out;
    }

    &--disabled {
      pointer-events: none;
      background-color: #fff3;

    }
  }
}
