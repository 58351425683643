@import "colors";

$font-sizes: (8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50);

//Flex

.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.align-items-center {
  align-items: center  !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-center {
   justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

//Other

.pointer {
  cursor: pointer !important;
}

.hidden {
  visibility: hidden !important;
}

.width-full {
  width: 100%;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//Rotate

.rotate-90deg {
  transform: rotate(90deg) !important;
}

.rotate-45deg {
  transform: rotate(45deg) !important;
}

.rotate-180deg {
  transform: rotate(180deg) !important;
}

.rotate-270deg {
  transform: rotate(270deg) !important;
}

//Text

.bold {
  font-weight: bold !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fc-black {
  color: var(--black);
}

.fc-white {
  color: var(--white);
}

.fc-error {
  color: var(--scarlet);
}

@each $size in $font-sizes {
  .fs-#{$size} { font-size: #{$size}px !important;  }
}

@each $size in $font-sizes {
  i[class^="icon-"].fs-#{$size}, i[class*=" icon-"].fs-#{$size} {
    width: #{$size}px;
    height: #{$size}px;
    min-height: #{$size}px;
    min-width: #{$size}px;
    font-size: #{$size}px;
  }

  i[class^="icon-"].fs-#{$size}:before, i[class*=" icon-"].fs-#{$size}:before {
    width: #{$size}px;
    height: #{$size}px;
    min-height: #{$size}px;
    min-width: #{$size}px;
    font-size: #{$size}px;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.opacity {

  &--0 {
    opacity: 0;
  }
}

.disabled-icon {
  color: var(--gray-60);
}

.nowrap {
  white-space: nowrap;
}