.bar-chart-tooltip,
.donut-chart-tooltip {
  
  &__title,
  &__value {
    font-size: 10px;
    font-weight: 500;
  }

  &__title {
    max-width: 140px;
    color: var(--gray-60);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: var(--black);
  }

  &__section {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
}


.bar-chart-tooltip {
  width: 190px;

  &--big {
    width: 250px;
  }

  &--xl {
    width: 300px;
  }
}