:root {
  --black: #242326;
  --white: #FFFFFF;

  --blue: #1E5CF2;
  --green: #27AE60;
  --scarlet: #EB4C27;

  --gray-5: #FCFCFC;
  --gray-10: #F7F8FA;
  --gray-20: #F2F3F5;
  --gray-30: #E3E4E8;
  --gray-40: #D0D2D6;
  --gray-50: #C3C6C9;
  --gray-60: #9BA2A8;
  --gray-70: #838991;
  --gray-80: #737980;
  --gray-90: #5C6166;
  --gray-100: #3A3E42;

  --yellow-10: #FFFEF2;
  --yellow-20: #FFFCE5;
  --yellow-40: #FFF7B2;
  --yellow-60: #FFF38C;
  --yellow-80: #FFEF61;
  --yellow-100: #FCE835;

  --lima-10: #FAFDEE;
  --lima-20: #F8FDE0;
  --lima-40: #F2FBC4;
  --lima-60: #EAF9A6;
  --lima-80: #E4F787;
  --lima-100: #DDF56B;

  --light-green-10: #F8FCF2;
  --light-green-20: #EEF8E2;
  --light-green-40: #D8F0BA;
  --light-green-60: #C2E898;
  --light-green-80: #B0E075;
  --light-green-100: #9CD953;

  --malachite-10: #F0FAF1;
  --malachite-20: #E8FAEB;
  --malachite-40: #BDE7C3;
  --malachite-60: #9CDBA5;
  --malachite-80: #7ACF86;
  --malachite-100: #5AC369;

  --radiance-10: #F0F5FD;
  --radiance-20: #E4ECFD;
  --radiance-40: #AECAFD;
  --radiance-60: #86B1FA;
  --radiance-80: #5E96F9;
  --radiance-100: #377CF6;

  --dodger-blue-10: #F3F3FD;
  --dodger-blue-20: #E6E6FD;
  --dodger-blue-40: #BDBDFC;
  --dodger-blue-60: #9D9CF9;
  --dodger-blue-80: #7D7CF7;
  --dodger-blue-100: #5B5AF6;

  --violet-10: #FAF2F8;
  --violet-20: #F0DFEC;
  --violet-40: #E0BCDA;
  --violet-60: #CC95C2;
  --violet-80: #B264A4;
  --violet-100: #9C368A;

  --blick-red-10: #FAF2F3;
  --blick-red-20: #F5DFE2;
  --blick-red-40: #E4B1BA;
  --blick-red-60: #D68B97;
  --blick-red-80: #C96474;
  --blick-red-100: #BD3D52;

  --scarlet-10: #FCF3F2;
  --scarlet-20: #FCECEB;
  --scarlet-40: #F7B7A8;
  --scarlet-60: #F3937D;
  --scarlet-80: #EE7052;
  --scarlet-100: #EA4C27;

  --pumpkin-10: #FDF5F0;
  --pumpkin-20: #FDEFE6;
  --pumpkin-40: #F9D1B0;
  --pumpkin-60: #F4B989;
  --pumpkin-80: #F2A162;
  --pumpkin-100: #EF8A3A;

  --amber-10: #FFFBED;
  --amber-20: #FFF8E0;
  --amber-40: #FFECB3;
  --amber-60: #FFDD78;
  --amber-80: #FCCE44;
  --amber-100: #FCC238;

  --teal-10: #F0FCFA;
  --teal-20: #DFF7F2;
  --teal-40: #BAE8DF;
  --teal-60: #93DBCD;
  --teal-80: #5DCFB9;
  --teal-100: #3BC3A8;

  --cyan-10: #EDF9FA;
  --cyan-20: #DCF8FA;
  --cyan-40: #B5EEF5;
  --cyan-60: #88DFEB;
  --cyan-80: #51CADB;
  --cyan-100: #2BBACF;

  --deep-violet-10: #F5F2FA;
  --deep-violet-20: #E9E1F5;
  --deep-violet-40: #D9CEED;
  --deep-violet-60: #A186D1;
  --deep-violet-80: #825CC4;
  --deep-violet-100: #673CB5;

  --pink-10: #FCF2F9;
  --pink-20: #FCE8F5;
  --pink-40: #F7D7EC;
  --pink-60: #F7B7E2;
  --pink-80: #F279CA;
  --pink-100: #ED4CB7;

  --light-gray: #F8F8F8;
  --dark-gray: #EFEFEF;
}

.color-gray {

  &--5 {
    color: var(--gray-5)
  }

  &--10 {
    color: var(--gray-10)
  }

  &--20 {
    color: var(--gray-20)
  }

  &--30 {
    color: var(--gray-30)
  }

  &--40 {
    color: var(--gray-40)
  }

  &--50 {
    color: var(--gray-50)
  }

  &--60 {
    color: var(--gray-60)
  }

  &--70 {
    color: var(--gray-70)
  }

  &--80 {
    color: var(--gray-80)
  }

  &--90 {
    color: var(--gray-90)
  }

  &--100 {
    color: var(--gray-100)
  }
}

.color-scarlet {

  &--10 {
    color: var(--scarlet-10)
  }

  &--20 {
    color: var(--scarlet-20)
  }

  &--40 {
    color: var(--scarlet-40)
  }

  &--60 {
    color: var(--scarlet-60)
  }

  &--80 {
    color: var(--scarlet-80)
  }

  &--100 {
    color: var(--scarlet-100)
  }
}