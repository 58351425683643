timepicker {
  .bs-timepicker-field {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 4px;
    width: 30px;
    color: var(--black);

    &:focus {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: var(--white);
    }
  }
}
