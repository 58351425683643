.button-toggle-group__container {
  display: flex;
  background-color: var(--white);
  border-radius: 100px;

  &.button-toggle__container-extrasmall {
    height: 20px;
  }

  &.button-toggle__container-small {
    height: 30px;
  }

  &.button-toggle__container-medium {
    height: 40px;
  }

  &.button-toggle__container-big {
    height: 50px;
  }
}

.button-toggle-group--disabled {
  opacity: .7;
  pointer-events: none;
}
