.ap-island-actions {
  position: sticky;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: inline-flex;
  height: 56px;
  border-radius: 100px;
  overflow: hidden;
  background-color: var(--black);

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    padding: 0 10px;
    font-size: 12px;
    position: relative;

    &--column {
      flex-direction: column !important;
    }

    &:first-child {
      flex-direction: row;
      padding: 0 10px 0 18px;
    }

    &:last-child {
      padding: 0 18px 0 10px;
    }
    
    // &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 38px;
        position: absolute;
        right: 0;
        background-color: var(--gray-90);
        border-radius: 1px;
      }
    // }

    &:not(:first-child) {
      cursor: pointer;
      &:hover {
        background-color: var(--gray-100);
      }
      &:active {
        background-color: var(--gray-90);
      }
    }

    &--disabled {
      cursor: not-allowed;

      i, span {
        opacity: 0.5;
      }
    }
  }

  &__count {
    color: var(--gray-40);
    font-weight: 400;
    margin-right: 4px;
  }

  &__deselect {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
}
