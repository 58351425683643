.radio-container {
  display: flex;
  align-items: center;

  &:hover {
    input[type=radio]:before {
      border-color: var(--black);
    }
  }

  input[type=radio] {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-right: 8px;

    &:disabled:before {
      border: 2px solid var(--gray-30);
      background-color: var(--gray-20);
      cursor: default;
    }
  }

  input[type=radio]:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--gray-50);

    &:hover {
      border-color: var(--black);
    }
  }

  input[type=radio]:checked:before {
    border: 6px solid var(--black);
    background-color: var(--white);
  }

  input[type=radio]:checked:disabled:before {
    border: 6px solid var(--gray-30);
    background-color: var(--white);
  }

  label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &--single {
    width: 20px;

    input[type=radio] {
      margin-right: 0;
    }
  }
}
