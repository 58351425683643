.ap-ui-chip {
  height: 30px;
  min-width: 60px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 12px;
  border: 1px solid var(--gray-30);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: var(--gray-10);
  }
  &--disabled {
    background-color: var(--gray-20);
    color: var(--gray-40);
    cursor: default;
    pointer-events: none;
    &:hover {
      background-color: var(--gray-20);
    }
  }
  &--active {
    border: 1px solid var(--black);
    background-color: var(--black);
    color: var(--white);
    &:hover {
      background-color: var(--gray-90);
    }
    &.ap-ui-chip--disabled {
      background-color: var(--gray-40);
      border: var(--gray-40);
      &:hover {
        background-color: var(--gray-40);
      }
    }
  }
}
