ngx-intl-tel-input {
  width: 100%;

  input {
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 8px;
  }

  .dropdown-menu {
    border: 1px solid var(--black) !important;
    border-radius: 8px !important;
    inset: 120% auto auto 0 !important;
    width: 350px !important;
  }

  .search-container {

    input {
      border: none !important;
    }
  }

  .iti {
    width: 100%;

    input#country-search-box {
      padding: 0 16px;
    }

    &__country-list {
      box-shadow: none;
      max-height: 160px;
      border-radius: 8px;
    }

    &__country {
      padding: 8px 12px;
    }

    &__flag-container {
      padding: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &__selected-flag {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
