.hint {
  z-index: 2000;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--black);
  cursor: default;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-100);
  }

  &__text {
    color: var(--gray-40);
    font-size: 12px;
    font-weight: 400;
    margin-top: 12px;
    line-height: 150%;
    white-space: normal;
    
    &--bold {
      font-weight: 600;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}