html {
  body {
    // styles when container appendTo="body"
    ng-dropdown-panel {
      border: 1px solid var(--gray-30) !important;
      box-shadow: 0 0 4px rgba(94, 97, 101, 0.3);
      border-radius: 8px !important;
      overflow: hidden;
      z-index: 1055 !important;
      &.ng-select-bottom {
        margin-top: 2px !important;
      }

      * {
        outline: none !important;
        box-shadow: none !important;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px !important;
        letter-spacing: 0;
        font-weight: 500;
      }

      .ng-option {
        position: relative;
        padding: 12px 16px !important;
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        white-space: normal !important;

        &:hover {
          background-color: var(--gray-20) !important;
        }

        &.ng-option-selected,
        &.ng-option-marked {
          background-color: var(--gray-20) !important;
        }
      }
    }
    // styles when container appendTo="body"

    .ng-select.dropdown,
    .edit-client.dropdown {

      * {
        outline: none !important;
        box-shadow: none !important;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px !important;
        letter-spacing: 0;
        font-weight: 500;
      }

      &.ng-select-opened>.ng-select-container .ng-arrow {
        transform: rotate(180deg);
      }

      &.ng-select-disabled {
        cursor: default;
        .ng-select-container {
          cursor: default;
          &:hover {
            border-color: var(--gray-30)!important;
          }
          * {
            cursor: default;
            color: var(--gray-60);
            .ng-value .ng-value-label {
              color: var(--gray-100)
            }
          }
          .ng-arrow-wrapper {
            .ng-arrow {
              background: url('/assets/images/icons/ng-select/chevron-down--gray.svg') center center no-repeat !important;
            }
          }
        }
      }

      .ng-select-container {
        height: 40px;
        border-radius: 8px;
        border: 1px solid var(--gray-30) !important;

        &:hover {
          border-color: var(--gray-50) !important;
          transition: .2s ease-in-out;
        }

        .ng-input,
        .ng-value-container {
          padding-top: 0 !important;
          padding-left: 12px !important;
          line-height: 28px !important;

          .ng-placeholder {
            position: relative;
            top: 0;
            padding: 0;
            color: var(--gray-40);
          }
        }

        .ng-arrow-wrapper {
          margin-right: 8px;

          .ng-arrow {
            top: 3px;
            width: 14px;
            height: 14px;
            background: url('/assets/images/icons/ng-select/chevron-down.svg') center center no-repeat !important;
            background-size: contain;
            border: none !important;
          }
        }
      }

      &.ng-select-opened {

        .ng-select-container {
          border-color: var(--black) !important;
          transition: .2s ease-in-out;
        }

        &.ng-select-bottom>.ng-select-container,
        &.ng-select-top>.ng-select-container {
          border-radius: 8px !important;
        }
      }

      .ng-dropdown-panel {
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto;

        &.ng-select-bottom {
          margin-top: 4px;
          border-radius: 8px !important;
          overflow: hidden;
          border: 1px solid var(--gray-30);
        }

        &.ng-select-top {
          margin-bottom: 4px;
          border-radius: 8px !important;
          overflow: hidden;
        }

        .ng-dropdown-panel-items {

          .ng-option {
            position: relative;
            padding: 12px 16px;

            &:hover {
              background-color: var(--gray-20);
            }

            &.ng-option-selected,
            &.ng-option-marked {
              background-color: var(--gray-20);
            }
          }
        }

        .ng-dropdown-footer {
          position: relative;
          border-top: 1px solid var(--gray-30);
          box-shadow: 0px 0px 4px rgba(115, 121, 128, 0.3) !important;
        }

        .ng-dropdown-header {
          border: none;
          padding: 12px 16px;
        }
      }

      &--ghost {

        .ng-select-container {
          border: none !important;
          border-color: transparent !important;

          * {
            color: var(--gray-60);
          }
        }
      }

      &--height-48 {

        .ng-select-container {
          height: 48px;

          .ng-value-container .ng-input {
            top: 9px;

            input {
              line-height: 25px;
            }
          }
        }
      }

      &--contained-gray {
        .ng-select-container {
          border: none !important;
          border-color: transparent !important;
          background: var(--gray-20) !important;

          * {
            color: var(--black);
          }
        }
      }
    }

    .ng-select.ng-select-multiple.dropdown--no-items {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            display: none !important;
          }
        }
      }
    }

    .dropdown-values {
      display: flex;
      flex-wrap: wrap;
      min-height: 30px;
      width: 100%;
      margin-top: 6px;
    }
  }
}

// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES
// REFACTOR ALL STYLES

.dropdown-container {
  position: relative;

  &__icon {
    position: absolute !important;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 10000;
  }

  .dropdown {
    &--icon-left {
      .ng-select-container {
        .ng-value-container {
          .ng-input {
            padding: 0 0 0 30px !important;
          }
          .ng-value,
          .ng-placeholder {
            padding: 0 0 0 12px !important;
          }
        }
      }
    }
  }
}

.ng-select.ng-select-multiple.dropdown {
  &--multiple {
    &.dropdown.ng-select-opened {
      .ng-select-container {
        border-color: var(--black) !important;
      }
    }

    .ng-select-container {
      height: unset !important;
      min-height: 40px;
      padding: 0;

      .ng-value-container {
        padding-left: 12px !important;
        padding-top: 5px !important;
        min-height: 40px;
        .ng-value {
          background: var(--black);
          border-radius: 100px !important;
        }
      }

      .ng-arrow-wrapper {
        display: none;
      }

      .ng-clear-wrapper {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        .ng-clear {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}


.multiple-label {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0px 10px 0 12px;
  color: white;

  &--width-320px {

    .multiple-label__title {
      max-width: 320px;
    }
  }

  &__title {
    width: 100%;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 150%;
    color: var(--white);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__clear-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    background: var(--white);
    border-radius: 50%;
  }

  i {
    color: var(--gray-100)
  }
}
