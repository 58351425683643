.tabs-nav {
  display: flex;
  height: 30px;

  &__item {
    display: flex;
    align-items: flex-end;
    height: 30px;
    padding: 0 12px 4px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--gray-50);
    border-bottom: 1px solid var(--gray-20);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: var(--black);
      border-bottom: 1px solid var(--gray-60);
    }

    &--active {
      color: var(--black);
      border-bottom: 2px solid var(--black);

      &:hover {
        border-bottom: 2px solid var(--black);
      }
    }
  }

  &__item-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    min-width: 20px;
    height: 20px;
    margin-left: 6px;
    border-radius: 100px;
    background: var(--gray-20);
    color: var(--gray-100);
    font-size: 10px;
    font-weight: 600;
  }
}
