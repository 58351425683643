.ap-ui-label {
  height: 24px;
  width: fit-content;
  padding: 0 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  background-color: var(--gray-10);
  cursor: pointer;
}
