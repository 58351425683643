@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import 'ngx-toastr/toastr';

@import "assets/styles/colors";
@import "assets/styles/space-generator";
@import "assets/styles/inputs";
@import "assets/styles/checkbox";
@import "assets/styles/buttons";
@import "assets/styles/ng-select";
@import "assets/styles/fonts";
@import "assets/styles/toastr";
@import "assets/styles/tooltip";
@import "assets/styles/datepicker";
@import "assets/styles/modals";
@import "assets/styles/pagination";
@import "assets/styles/helpers";
@import "assets/styles/tour-step";
@import "assets/styles/timepicker";
@import "assets/styles/form-field";
@import "assets/styles/button-toggle";
@import "assets/styles/intl-tel-input";
@import "assets/styles/icons";
@import "assets/styles/tabs";
@import "assets/styles/tags";
@import "assets/styles/file-upload";
@import "assets/styles/table-footer";
@import "assets/styles/status";
@import "assets/styles/filters";
@import "assets/styles/context-menu";
@import "assets/styles/island-actions";
@import "assets/styles/notification";
@import "assets/styles/sidebar-filters";
@import "assets/styles/tile-selector";
@import "assets/styles/chips";
@import "assets/styles/radio";
@import "assets/styles/label";
@import "assets/styles/widgets";
@import "assets/styles/accordion";
@import "assets/styles/autocomplete";
@import "assets/styles/chart";
@import "assets/styles/button-icon";
@import "assets/styles/width-generator";
@import "assets/styles/chart-tooltip";
@import "assets/styles/empty";
@import "assets/styles/shipment-option-description";
@import "assets/styles/animations";
@import "assets/styles/side-panel";
@import "assets/styles/hint";
@import "assets/styles/textarea";

@import "assets/fontello/css/fontello.css";

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  * {
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    
    ::-webkit-scrollbar-thumb {
      width: 6px;
      background: var(--gray-60);
      border-radius: 8px;
    } 
  }
}

@media screen and (max-width: 768px) {
  body {
    padding-top: env(safe-area-inset-top, 1em);
    padding-right: env(safe-area-inset-right, 1em);
    padding-bottom: env(safe-area-inset-bottom, 1em);
    padding-left: env(safe-area-inset-left, 1em);
  }
}
