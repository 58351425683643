.empty-state {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 90px);

  &--no-height {
    height: unset;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 536px;
    max-width: 536px;
  }

  &__image {
    width: 250px;
    height: 200px;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--small {
      width: 198px;
      height: 160px;
    }

    &--xs {
      width: 120px;
      height: 92px;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0;
    color: var(--black);
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0;
    text-align: center;
    color: var(--gray-80);
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    &--column {
      flex-direction: column;
    }
  }

  &__inline-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__bottom-inline-alert {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }
}