@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?29390893');
  src: url('../font/fontello.eot?29390893#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?29390893') format('woff2'),
       url('../font/fontello.woff?29390893') format('woff'),
       url('../font/fontello.ttf?29390893') format('truetype'),
       url('../font/fontello.svg?29390893#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?29390893#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-superadmin:before { content: '\e800'; } /* '' */
.icon-chat:before { content: '\e801'; } /* '' */
.icon-eye-open:before { content: '\e802'; } /* '' */
.icon-error-filled:before { content: '\e803'; } /* '' */
.icon-copy:before { content: '\e804'; } /* '' */
.icon-customer-service:before { content: '\e805'; } /* '' */
.icon-products:before { content: '\e806'; } /* '' */
.icon-filter:before { content: '\e807'; } /* '' */
.icon-indeterminate:before { content: '\e808'; } /* '' */
.icon-instagram:before { content: '\e809'; } /* '' */
.icon-linkedin:before { content: '\e80a'; } /* '' */
.icon-more-vertical-filled:before { content: '\e80b'; } /* '' */
.icon-arrow-up-stroke:before { content: '\e80c'; } /* '' */
.icon-edit:before { content: '\e80d'; } /* '' */
.icon-coins:before { content: '\e80e'; } /* '' */
.icon-coins-growth:before { content: '\e80f'; } /* '' */
.icon-home:before { content: '\e810'; } /* '' */
.icon-edit-2:before { content: '\e811'; } /* '' */
.icon-box-2:before { content: '\e812'; } /* '' */
.icon-sales:before { content: '\e813'; } /* '' */
.icon-logistics:before { content: '\e814'; } /* '' */
.icon-order-help:before { content: '\e815'; } /* '' */
.icon-print:before { content: '\e816'; } /* '' */
.icon-info:before { content: '\e817'; } /* '' */
.icon-refunds:before { content: '\e818'; } /* '' */
.icon-returns:before { content: '\e819'; } /* '' */
.icon-lock:before { content: '\e81a'; } /* '' */
.icon-user:before { content: '\e81b'; } /* '' */
.icon-send:before { content: '\e81c'; } /* '' */
.icon-logo:before { content: '\e81d'; } /* '' */
.icon-status:before { content: '\e81e'; } /* '' */
.icon-b2b:before { content: '\e81f'; } /* '' */
.icon-chat-open:before { content: '\e820'; } /* '' */
.icon-copy-link:before { content: '\e821'; } /* '' */
.icon-shipment-label:before { content: '\e822'; } /* '' */
.icon-circle-point:before { content: '\e823'; } /* '' */
.icon-tracking-number:before { content: '\e824'; } /* '' */
.icon-table-layout:before { content: '\e825'; } /* '' */
.icon-location:before { content: '\e826'; } /* '' */
.icon-info-circle:before { content: '\e827'; } /* '' */
.icon-info-filled:before { content: '\e828'; } /* '' */
.icon-sidebar-close:before { content: '\e829'; } /* '' */
.icon-sidebar-open:before { content: '\e82a'; } /* '' */
.icon-cookies:before { content: '\e82b'; } /* '' */
.icon-item-list:before { content: '\e82c'; } /* '' */
.icon-info-2:before { content: '\e82d'; } /* '' */
.icon-checkmark-stroke:before { content: '\e82e'; } /* '' */
.icon-consignment:before { content: '\e82f'; } /* '' */
.icon-calibration:before { content: '\e830'; } /* '' */
.icon-test-print:before { content: '\e831'; } /* '' */
.icon-upload:before { content: '\e832'; } /* '' */
.icon-refresh:before { content: '\e833'; } /* '' */
.icon-target:before { content: '\e834'; } /* '' */
.icon-shipment-track:before { content: '\e835'; } /* '' */
.icon-shipment-return:before { content: '\e836'; } /* '' */
.icon-web:before { content: '\e837'; } /* '' */
.icon-youtube:before { content: '\e838'; } /* '' */
.icon-alert:before { content: '\e839'; } /* '' */
.icon-search-plus:before { content: '\e83a'; } /* '' */
.icon-products-1:before { content: '\e83b'; } /* '' */
.icon-share:before { content: '\e83c'; } /* '' */
.icon-attach:before { content: '\e83d'; } /* '' */
.icon-layout:before { content: '\e83e'; } /* '' */
.icon-barcode:before { content: '\e83f'; } /* '' */
.icon-more-horizontal-filled:before { content: '\e840'; } /* '' */
.icon-more-pagination:before { content: '\e841'; } /* '' */
.icon-business:before { content: '\e842'; } /* '' */
.icon-send-message:before { content: '\e843'; } /* '' */
.icon-shipment-create:before { content: '\e844'; } /* '' */
.icon-twitter:before { content: '\e845'; } /* '' */
.icon-arrow-down-left:before { content: '\e846'; } /* '' */
.icon-arrow-down-stroke:before { content: '\e847'; } /* '' */
.icon-arrow-up-right:before { content: '\e848'; } /* '' */
.icon-file-download:before { content: '\e849'; } /* '' */
.icon-invoice:before { content: '\e84a'; } /* '' */
.icon-dashboard:before { content: '\e84b'; } /* '' */
.icon-eye-closed:before { content: '\e84c'; } /* '' */
.icon-clock:before { content: '\e84d'; } /* '' */
.icon-help:before { content: '\e84e'; } /* '' */
.icon-mail:before { content: '\e84f'; } /* '' */
.icon-notifications:before { content: '\e850'; } /* '' */
.icon-download:before { content: '\e851'; } /* '' */
.icon-file-add:before { content: '\e852'; } /* '' */
.icon-reports:before { content: '\e853'; } /* '' */
.icon-settings:before { content: '\e854'; } /* '' */
.icon-b2c:before { content: '\e855'; } /* '' */
.icon-user-add:before { content: '\e856'; } /* '' */
.icon-loader:before { content: '\e857'; } /* '' */
.icon-duplicate:before { content: '\e858'; } /* '' */
.icon-calendar:before { content: '\e859'; } /* '' */
.icon-search:before { content: '\e85a'; } /* '' */
.icon-shipment-collected:before { content: '\e85b'; } /* '' */
.icon-archive:before { content: '\e85c'; } /* '' */
.icon-move-to:before { content: '\e85d'; } /* '' */
.icon-link-open:before { content: '\e85e'; } /* '' */
.icon-expand:before { content: '\e85f'; } /* '' */
.icon-facebook:before { content: '\e860'; } /* '' */
.icon-box:before { content: '\e861'; } /* '' */
.icon-delete:before { content: '\e862'; } /* '' */
.icon-drag-dots:before { content: '\e863'; } /* '' */
.icon-arrow-right-filled:before { content: '\e864'; } /* '' */
.icon-close-stroke:before { content: '\e865'; } /* '' */
.icon-arrow-right-stroke:before { content: '\e866'; } /* '' */
.icon-add-stroke:before { content: '\e867'; } /* '' */
.icon-check-mark-filled:before { content: '\e868'; } /* '' */
.icon-add-filled:before { content: '\e869'; } /* '' */
.icon-arrow-left-stroke:before { content: '\e86a'; } /* '' */
.icon-merge:before { content: '\e86b'; } /* '' */
.icon-close-filled:before { content: '\e86c'; } /* '' */
.icon-resend:before { content: '\e86d'; } /* '' */
.icon-unlink:before { content: '\e86e'; } /* '' */
.icon-user-id:before { content: '\e86f'; } /* '' */
.icon-sort:before { content: '\e870'; } /* '' */
.icon-puzzle:before { content: '\e871'; } /* '' */
.icon-prices:before { content: '\e872'; } /* '' */
.icon-export:before { content: '\e873'; } /* '' */
.icon-email-send:before { content: '\e874'; } /* '' */
.icon-import:before { content: '\e875'; } /* '' */
.icon-package:before { content: '\e876'; } /* '' */
.icon-star:before { content: '\e877'; } /* '' */
.icon-star-filled:before { content: '\e878'; } /* '' */
.icon-calendar-week:before { content: '\e879'; } /* '' */
.icon-calendar-month:before { content: '\e87a'; } /* '' */
.icon-contact-book:before { content: '\e87b'; } /* '' */
.icon-discount:before { content: '\e87c'; } /* '' */
.icon-arrow-grow-down:before { content: '\e87d'; } /* '' */
.icon-arrow-grow-up:before { content: '\e87e'; } /* '' */
.icon-coins-downturn:before { content: '\e87f'; } /* '' */
.icon-booking-cancel:before { content: '\e880'; } /* '' */
.icon-client-profit:before { content: '\e881'; } /* '' */
.icon-warehouse:before { content: '\e882'; } /* '' */
.icon-reseller:before { content: '\e883'; } /* '' */
.icon-data-edit:before { content: '\e884'; } /* '' */
.icon-add-company:before { content: '\e885'; } /* '' */
.icon-onboarding:before { content: '\e886'; } /* '' */
.icon-lamp-spark:before { content: '\e887'; } /* '' */
.icon-file-pdf:before { content: '\e888'; } /* '' */
.icon-file-csv:before { content: '\e889'; } /* '' */
.icon-additional-service:before { content: '\e88a'; } /* '' */
.icon-more-vertical:before { content: '\e88b'; } /* '' */
.icon-integration:before { content: '\e88c'; } /* '' */
.icon-sidebar-view:before { content: '\e88d'; } /* '' */
.icon-window-view:before { content: '\e88e'; } /* '' */
.icon-order:before { content: '\e88f'; } /* '' */
.icon-order-number:before { content: '\e890'; } /* '' */
.icon-slash-divider:before { content: '\e891'; } /* '' */
.icon-delivery:before { content: '\e892'; } /* '' */
.icon-booking-repeat:before { content: '\e893'; } /* '' */
.icon-location-list:before { content: '\e894'; } /* '' */
