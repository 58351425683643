.ap-context-menu {
  position: absolute;
  z-index: 100;
  top: calc(100% + 4px);
  right: 0;
  display: flex;
  flex-direction: column;
  width: max-content;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 4px 6px -2px rgba(58, 62, 66, 0.03), 0px 8px 12px -4px rgba(58, 62, 66, 0.08);
  border: 1px solid var(--gray-30);

  &--hide {
    visibility: hidden;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    min-width: 130px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 18px;
    color: var(--black);
    font-weight: 500;
    background-color: var(--white);
    cursor: pointer;
    transition: .1s ease-out;

    &:hover {
      background-color: var(--gray-10);
      transition: .1s ease-out;
    }

    &--disabled {
      color: var(--gray-40);
      pointer-events: none;

      i:not(.color-scarlet--100) {
        color: var(--gray-40) !important;
      }
    }

    &--red {
      color: var(--scarlet-100);

      &.ap-context-menu__item--disabled {
        color: var(--gray-40);
        pointer-events: none;
      }
    }

    &--border-top {
      border-top: 1px solid var(--gray-80);
    }

    i:not(.color-scarlet--100) {
      margin-right: 8px;
      color: var(--gray-80);
    }

    &--gray {
      color: var(--gray-40);
      font-weight: 400;
    }
  }
}
