.sidebar-filters {
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 103;
  width: 544px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(155, 162, 168, 0.25);
  transition: .7s ease-out;
  &--opened {
    right: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 24px;
    border-bottom: 1px solid var(--gray-30);
  }
  &__body {
    min-height: calc(100% - 163px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
    border-top: 1px solid var(--gray-30);
    &--both-sides {
      justify-content: space-between;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    &:hover {
      background-color: var(--gray-10);
    }
    &:not(:first-child) {
      border-top: 1px solid var(--gray-20);
    }
    &-header {
      height: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin: 24px;
      i {
        transition: .3s;
      }
    }
    &-body {
      overflow: hidden;
      height: 0;
      transition: all .5s;
      &--inner {
        display: flex;
        flex-direction: column;
        margin-top: -100%;
        transition: all .5s;
      }
    }
    &--expanded {
      &:hover {
        background-color: initial;
      }
      .sidebar-filters__section-header {
        i {
          transform: rotate(-90deg);
          transition: .3s;
        }
      }
      .sidebar-filters__section-body {
        height: fit-content;
        padding: 0 24px 24px 24px;
        .sidebar-filters__section-body--inner {
          margin-top: 0;
        }
      }
    }
  }
}
.sidebar-filters-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  background-color: var(--gray-40);
  opacity: .5;
}
