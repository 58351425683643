.modal-content {
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(155, 162, 168, 0.25);

  .modal__container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    max-height: 96vh;
    overflow: auto;

    .modal__body {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
    }
  }

  .modal__header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background: var(--white);
    border-radius: 16px 16px 0 0;

    .modal__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: var(--black);
    }

    &--border {
      padding: 16px 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid var(--gray-30);
    }
  }

  .modal__footer, .modal__footer--both-side {
    display: flex;
    align-items: center;
    padding: 24px;
  }

  .modal__footer {
    justify-content: flex-end;

    &--both-side {
      justify-content: space-between;
    }

    &--border {
      padding: 16px 24px;
      margin-top: 24px;
      border-top: 1px solid var(--gray-30);
    }
  }

  .modal__loader {
    position: relative;
    height: 150px;
    .loader {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.modal-backdrop {
  background: var(--gray-40) !important;
  opacity: .5 !important;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;

  &--custom {
    max-width: 800px;
  }

  &--880px {
    max-width: 880px;
  }

  &--price-group-note {
    max-width: 506px;
  }

  &--ultra-wide {
    max-width: 1044px;
  }

  &--short {
    max-width: 500px;
  }

  &--height-800 {
    max-width: 900px;

    .modal-content {
      width: 100%;
      height: 800px;
    }
  }
}
