.button-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  i {
    color: var(--white);
  }
  &:hover {
    background-color: var(--gray-90);
  }
  &:active {
    background-color: var(--gray-100);
  }
  &:disabled {
    background-color: var(--gray-20);
    i {
      color: var(--gray-40);
    }
  }
  &--neutral {
    background-color: var(--gray-20);
    i {
      color: var(--black);
    }
    &:hover {
      background-color: var(--gray-30);
    }
    &:active {
      background-color: var(--gray-50);
    }
    &:disabled {
      background-color: var(--gray-20);
      i {
        color: var(--gray-40);
      }
    }
  }
  &--secondary {
    border-radius: 8px;
    background-color: var(--white);
    i {
      color: var(--black)
    }
    &:hover {
      background-color: var(--gray-10);
    }
    &:active {
      background-color: var(--gray-20);
    }
    &:disabled {
      background-color: var(--white);
      i {
        color: var(--gray-40);
      }
    }
    &.button-icon--small {
      border-radius: 6px;
    }
    &.button-icon--extra-small {
      border-radius: 4px;
    }
  }
  &--tertiary {
    border-radius: 8px;
    background-color: var(--white);
    border: 1px solid var(--gray-30);
    i {
      color: var(--black)
    }
    &:hover {
      background-color: var(--gray-10);
      border: 1px solid var(--gray-40);
    }
    &:active {
      background-color: var(--gray-20);
      border: 1px solid var(--gray-40);
    }
    &:disabled {
      background-color: var(--white);
      border: 1px solid var(--gray-20);
      i {
        color: var(--gray-40);
      }
    }
    &.button-icon--small {
      border-radius: 6px;
    }
    &.button-icon--extra-small {
      border-radius: 4px;
    }
  }
  &--large {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
  &--medium {
    width: 36px;
    min-width: 36px;
    height: 36px;
  }
  &--small {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
  &--extra-small {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}
