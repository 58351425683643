.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
  height: 48px;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
  background-color: var(--gray-20);
  border: none;
	transition: .1s ease-in-out;
	user-select: none;
	outline: none;
	cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: var(--gray-30);
    transition: .1s ease-in-out;
  }
  &:active {
    background-color: var(--gray-50);
    transition: .1s ease-in-out;
  }
	&:disabled {
    color: var(--gray-40);
    background-color: var(--gray-20);
    cursor: default;
    &:hover {
      color: var(--gray-40);
      background-color: var(--gray-20);
    }
	}

  &--contained-black {
    background: var(--black);
    color: var(--white);
    &:disabled {
      background-color: var(--gray-40);
      color: var(--gray-20);
      &:hover {
        background-color: var(--gray-40);
        color: var(--gray-20);
      }
    }
    &:hover {
      background-color: var(--gray-90);
      transition: .1s ease-in-out;
    }
    &:active {
      background-color: var(--gray-100);
      transition: .1s ease-in-out;
    }
  }

  &--contained-gray {
    background: var(--gray-20);
    color: var(--black);
    &:disabled {
      color: var(--gray-40);
      background-color: var(--gray-20);
      &:hover {
        color: var(--gray-40);
        background-color: var(--gray-20);
      }
    }
    &:hover {
      background-color: var(--gray-30);
      transition: .1s ease-in-out;
    }
    &:active {
      background-color: var(--gray-50);
      transition: .1s ease-in-out;
    }
  }

  &--outlined {
    background-color: var(--white);
    border: 1px solid var(--gray-30);
    &:disabled {
      color: var(--gray-40);
      background-color: var(--white);
      &:hover {
        color: var(--gray-40);
        background-color: var(--white);
      }
    }
    &:hover {
      background-color: var(--gray-10);
      border: 1px solid var(--gray-40);
      transition: .1s ease-in-out;
    }
    &:active {
      background-color: var(--gray-20);
      border: 1px solid var(--gray-40);
      transition: .1s ease-in-out;
    }
  }

  &--round-sides {
    border-radius: 40px;
  }

  &--width-100 {
    width: 100%;
  }

  &--height-20 {
    padding: 2px 6px;
    height: 20px;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0;
    border-radius: 4px;
  }

  &--height-30 {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    border-radius: 6px;
  }

  &--height-36 {
    padding: 8px 12px;
    height: 36px;
    font-size: 14px;
  }

  &--height-40 {
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
  }

  &--circle-30 {
    width: 30px;
    padding: 0;
    border-radius: 50%;
  }

  &--focused {
    background-color: var(--gray-50);
    transition: .1s ease-in-out;    
  }

  &--boadred-gray {
    border: 1px solid var(--gray-30);
    background: var(--white);
    color: var(--black);
  }
}
