popover-container {
  border: none!important;
  width: 250px;

  &.popover-end {
    .popover-arrow:after {
      border-right-color: var(--black)!important;
    }
  }

  &.popover-bottom {
    .popover-arrow:after {
      border-bottom-color: var(--black)!important;
      top: 2px
    }
  }
}

.popover-header {
  display: none;
}

.popover-content {
  background-color: var(--black);
  border-radius: 8px;
  border: none;
}

.tour-step-container {
  background-color: var(--black);
  padding: 5px;
  color: var(--white);

  &__counter {
    color: var(--gray-60);
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 20px;
    background-color: var(--white);
    color: var(--black);
    cursor: pointer;
    border-radius: 100px;
    border: none;
  }
}
