.status {
  width: fit-content;
  max-height: 30px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
  border-radius: 30px;
  white-space: nowrap;
  color: var(--black);

  &--disabled {
    color: var(--gray-40);
    background: var(--gray-20);
    border-color: var(--gray-30);
    cursor: default;
    pointer-events: none;
  }

  &--height-40 {
    display: flex;
    align-items: center;
    max-height: 40px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
  }

  &--height-20 {
    max-height: 20px;
    padding: 2px 8px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
  }

  &--simple {
    line-height: 12px;
    border: 1px solid var(--gray-30);
    cursor: pointer;

    &:hover {
      background: var(--gray-10);
    }

    &:focus {
      background: var(--white);
      border-color: var(--black);
    }
  }

  &--simple-selected {
    border: 1px solid var(--black);
    background: var(--black);
    color: var(--white);

    &:hover {
      background: var(--gray-90);
      border-color: var(--gray-90);
      color: var(--white);
    }
  }

  &--secondary {
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;

    &.status--gray {
      background-color: var(--gray-60);
    }
    &.status--light-gray {
      background-color: var(--gray-40);
    }
    &.status--malachite {
      background-color: var(--malachite-60);
    }
    &.status--amber {
      background-color: var(--amber-60);
    }
    &.status--scarlet {
      background-color: var(--scarlet-60);
    }
    &.status--radiance {
      background-color: var(--radiance-60);
    }
  }

  &--tertiary {
    padding: 4px 6px;
    font-size: 10px;
  }

  &--outlined {
    border: 1px solid var(--black);
  }

  &--scarlet {
    background: var(--scarlet-20);
  }

  &--blick-red {
    background: var(--blick-red-20);
  }

  &--deep-violet {
    background: var(--deep-violet-20);
  }

  &--teal {
    background: var(--teal-20);
  }

  &--light-green {
    background: var(--light-green-20);
  }

  &--malachite {
    background: var(--malachite-20)
  }

  &--radiance {
    background: var(--radiance-20)
  }

  &--pink {
    background: var(--pink-20);
  }

  &--lima {
    background: var(--lima-20);
  }

  &--red {
    background: var(--blick-red-20);
  }

  &--cyan {
    background: var(--cyan-20);
  }

  &--dodger-blue {
    background: var(--dodger-blue-20);
  }

  &--yellow {
    background: var(--yellow-20);
  }

  &--pupmpkin {
    background: var(--pumpkin-20);
  }

  &--violet {
    background: var(--violet-20);
  }

  &--deep-violet {
    background: var(--deep-violet-20);
  }

  &--gray {
    background: var(--gray-20);
  }

  &--amber {
    background: var(--amber-20);
  }

  //TODO: replace this classes with exist ones.

  &--draft {
    background: var(--gray-20);
  }

  &--approved {
    background: var(--malachite-20);
  }

  &--block {
    background: var(--teal-20);
  }

  &--deprecated {
    background: var(--scarlet-20);
  }

  &--new {
    background: var(--cyan-20);
  }

  &--aaa,
  &--aa,
  &--a {
    background: var(--malachite-20);
  }

  &--an {
    background: var(--amber-20);
  }

  &--b {
    background: var(--pumkin-20);
  }

  &--c {
    background: var(--scarlet-20);
  }

  &--no-rating {
    background: var(--gray-20);
  }

  &--gray-30 {
    background: var(--gray-30);
  }
}

.status-dot {
  position: relative;
  padding-left: 16px;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &--green {

    &:before {
      background: var(--light-green-80);
    }
  }

  &--red {
    &:before {
      background: var(--scarlet-60);
    }
  }

  &--dark-green {
    &:before {
      background: var(--malachite-60);
    }
  }

  &--yellow {
    &:before {
      background: var(--amber-60);
    }
  }

  &--light-blue {
    &:before {
      background: var(--cyan-60);
    }
  }

  &--violet {
    &:before {
      background: var(--violet-60);
    }
  }

  &--blue {
    &:before {
      background: var(--radiance-60);
    }
  }

  &--gray {
    &:before {
      background: var(--gray-60);
    }
  }

  &--light-gray {
    &:before {
      background: var(--gray-40);
    }
  }
}
