.input {
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid var(--gray-30);
  border-radius: 8px;
  overflow: hidden;
  transition: .2s ease-in-out;

  &:hover {
    border: 1px solid var(--gray-50);
    transition: .2s ease-in-out;
  }

  &:focus,
  &:focus-within,
  &:active {
    border: 1px solid var(--black);
    transition: .2s ease-in-out;
  }

  input {
    background: var(--white);
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    outline: none;

    &::placeholder {
      color: var(--gray-40);
      font-weight: 500;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:disabled {
      opacity: 0.6;
      color: var(--dark)
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: var(--white) !important;
    color: var(--black) !important;
    caret-color: var(--black) !important;
    line-height: 36px !important;
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
    -webkit-text-fill-color: var(--black) !important;
    font-family: "Inter" !important;
    font-weight: 500 !important;
  }

  &--error {
    border: 1px solid var(--scarlet) !important;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      background-color: rgba(#FBDBD4, 50%) !important;
    }
  }

  &--disabled {
    border: 1px solid var(--gray-30);
    color: var(--gray-60);
    background-color: #FCFCFC;
    pointer-events: none;

    input {
      background-color: #FCFCFC;
      pointer-events: none;
    }

    &.input--with-prefix {
      .input__prefix {
        color: var(--gray-60);
      }
    }
  }

  &--disabled-filled {
    pointer-events: none;
    input {
      background-color: var(--gray-5);
      color: var(--gray-100);
      cursor: default;
    }
  }

  &--error-no-icon {
    border: 1px solid var(--scarlet) !important;

    input {
      padding: 0 16px;
    }

    &:after {
      content: none;
    }
  }

  &--success {
    border: 1px solid var(--malachite-100) !important;

    input {
      padding-right: 42px;
    }

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      right: 16px;
      width: 16px;
      height: 16px;
      background: url('/assets/images/icons/input/input-success.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &--height-48 {
    height: 48px;

    input {
      height: 48px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &--height-36 {
    height: 36px;

    input {
      height: 36px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &--search {
    padding-left: 12px;
    display: flex;
    align-items: center;
    input {
      padding: 0 12px 0 4px;
    }
    .icon-search {
      color: var(--gray-60)
    }
  }

  &--icon-right {

    input {
      padding-right: 48px;
    }
  }

  &--icon-left {
    input {
      padding-left: 36px;
    }

    i.input__icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      color: var(--black);
    }
  }

  &--with-prefix {
    display: flex;
    align-items: center;
    min-height: 40px;
    input {
      border-left: 1px solid var(--gray-30);
      margin-left: 12px;
      height: 38px;
    }
  }

  &--phone {
    display: flex;
    align-items: center;
    height: 40px;
    overflow: visible;
    background: var(--white);

    ::ng-deep {
      ngx-intl-tel-input {

        .separate-dial-code {
          height: 40px;
        }
        .iti__country-list {
          overflow-x: hidden;
        }
        .dropdown-menu {
          min-width: 300px !important;
          transform: translateY(10px);
          border-color: var(--gray-30) !important;
        }
        .iti__country-name {
          white-space: wrap
        }
      }
    }
  }

  &__prefix {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-90);
    margin-left: 12px;
  }

  &__postfix {
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-80);
    margin: 0 12px;
  }
}
