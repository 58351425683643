.ap-notification-ui {
  height: 30px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background-color: var(--gray-20);
  border-radius: 100px;
}
