i[class^="icon-"], i[class*=" icon-"] {
  position: relative;
  display: block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  font-size: 16px;
  &.icon--disabled {
    color: var(--gray-40);
    cursor: initial;
  }
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}
