.tile-selector {
  width: 160px;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-30);
  background-color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: var(--gray-10);
  }

  &--small {
    width: 118px;
    height: 64px;
    justify-content: center;
  }

  &--content-size {
    width: fit-content;
    height: fit-content;
  }

  &--disbled {
    background-color: var(--gray-20);
    color: var(--gray-40);
    pointer-events: none;
    &:hover {
      background-color: var(--gray-20);
    }
  }

  &--active {
    background-color: var(--gray-10);
    border-color: var(--black);

    &:hover {
      background-color: var(--white);
      border-color: var(--gray-60);
    }

    &.tile-selector--disabled {
      background-color: var(--gray-20);
      color: var(--gray-40);
      pointer-events: none;
      &:hover {
        background-color: var(--gray-20);
      }
    }
  }
}
