.autocomplete {
  position: relative;

  &__container {
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 10;
    max-height: 180px;
    width: 100%;
    background: var(--white);
    border-radius: 8px;
    border: 1px solid var(--gray-30);
    background: var(--white);
    box-shadow: 0px 8px 12px -4px rgba(var(--gray-10), 0.08), 0px 4px 6px -2px rgba(var(--gray-10), 0.03);
    overflow-y: auto;
  }

  &__item {
    padding: 12px 16px;
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;

    &:hover {
      background: var(--gray-10);
      cursor: pointer;
    }
  }
}