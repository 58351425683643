.textarea {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  textarea {
    display: block;
    resize: none;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    background: var(--gray);
    color: var(--ultra-white);
    border: 1px solid var(--gray-30);
    transition: .2 ease-in-out;

    &::placeholder {
      color: var(--gray-40);
    }

    &:focus,
    &:focus-within,
    &:active {
      border: 1px solid var(--black) !important;
      transition: .2 ease-in-out;
    }

    &:hover {
      border-color: var(--gray-50);
      transition: .2 ease-in-out;
    }

    &:before {
      display: none;
    }
  }

  &--error {

    textarea {
      border: 1px solid var(--scarlet) !important;
    }
  }

  &--white {

    textarea {
      background: var(--white);
      color: var(--black);
    }
  }
}
