.checkbox-container {
  display: flex;
  align-items: center;

  &--w-fit-content {
    width: fit-content;
  }

  &--small-label {
    
    label {
      margin-left: 8px;
      color: var(--black);
      font-size: 14px;
      font-weight: 500;
      line-height: 130%;
    }
  }

  input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-left: 1px;

    &:disabled {
      cursor: default;
    }
    &:hover:before {
      border-color: var(--gray-90);
    }
  }

  input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -1px;
    left: -1px;
    border: 2px solid var(--gray-50);
    border-radius: 4px;
    background-color: var(--white);
  }

  input[type=checkbox]:disabled:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -1px;
    left: -1px;
    border: 2px solid var(--gray-30)!important;
    border-radius: 4px;
    background-color: var(--gray-20)!important;
  }

  input[type=checkbox]:disabled:after {
    background: url('/assets/images/icons/checkbox/checked--gray.svg') center center no-repeat!important;
  }

  input[type=checkbox]:checked:before {
    border: 2px solid var(--black);
    background: var(--black);
  }

  input[type=checkbox]:checked:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    width: 12px;
    height: 12px;
    background: url('/assets/images/icons/checkbox/checked.svg') center center no-repeat;
    background-size: contain;
  }

  &--small {
    input[type=checkbox] {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }
    input[type=checkbox]:before {
      width: 16px;
      height: 16px;
      top: -1px;
      left: -1px;
    }
    input[type=checkbox]:checked:after {
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    color: var(--black);
    margin-bottom: 0;
    user-select: none;
    cursor: pointer;

    &--sub {
      font-size: 12px;
      line-height: normal;
      color: var(--gray-60);
    }
  }

  &__label--secondary {
    margin-top: 8px;
    font-size: 12px;
    line-height: 12px;
    color: var(--gray-60);
    font-weight: 400;
  }
}
