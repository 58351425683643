.tooltip {
  position: absolute;
  z-index: 4000;
  width: fit-content;
  min-width: 80px;
  max-width: 400px;
  padding: 16px;
  color: var(--gray-40);
  background: var(--black);
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  opacity: 0;
  border-radius: 8px;
  backdrop-filter: blur(4px);
  pointer-events: none;

  &--nowrap {
    white-space: nowrap;
  }

  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    &--bottom {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid var(--black);
      top: -7px;
    }
    &--bottom_right {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid var(--black);
      top: -7px;
    }
    &--top {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid var(--black);
      bottom: -7px;
    }
    &--right {
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid var(--black);
      left: -7px;
    }
    &--left {
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid var(--black);
      right: -7px;
    }
  }
}

.tooltip-show {
  opacity: 1;
}
