.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 0 20px 0;
  margin: 12px 0 0 0;

  &__counters-section {
    display: flex;
    align-items: center;
  }

  &__page-results-counter {
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px
  }

  &__all-results-counter {
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-60);

    &--black {
      color: var(--black);
    }
  }

  &__pages-nav-section {
    height: 40px;
    margin-left: auto;
  }

  &__selection {
    margin-left: 16px;
  }

  &__selection-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--gray-40);
  }
}
