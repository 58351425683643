.form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;

  &--overflow-hidden {
    overflow: hidden;
  }

  &--width-full {
    width: 100%;
  }

  &__title {
    font-size: 14px;
    color: var(--gray-90);
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: 500;

    &--elipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__subtitle {
    color: var(--gray-60);
    font-size: 10px;
    font-weight: 600;
    line-height: 150%;
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    color: var(--scarlet);
    padding-left: 20px;
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background: url('/assets/images/icons/input/input-danger.svg');
      background-size: contain;
    }

    &--nowrap {
      white-space: nowrap;
    }
  }

  &__count {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0;
    color: var(--gray-60);
  }

  &__hint-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    color: var(--gray-90);
    cursor: pointer;
    &--regular {
      position: initial;
      color: var(--gray-60);
      margin-left: 4px;
    }
  }

  &__clear-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &--small {
    .form-field__title {
      margin-bottom: 6px;
    }
    .form-field__error {
      top: calc(100% + 4px);
    }
    .form-field__count {
      top: calc(100% + 4px);
    }
  }
}
