.ap-filters {
  display: inline-flex;
  flex-direction: column;

  &__input-section {
    display: flex;
  }

  &__btn-container {
    position: relative;
    display: flex;
  }

  &__quick {
    position: relative;
    margin-right: 8px;
    &-wrapper {
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      z-index: 13;
      width: 500px;
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 16px;
      border: 1px solid var(--gray-40);
      background-color: var(--white);
    }
    &-body {
      margin-bottom: 24px;
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__button-active {
    background-color: var(--gray-20);
    i {
      transform: rotate(180deg);
    }
  }

  &__input {
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: 340px;
    margin-right: 8px;

    input {
      padding: 0 0 0 4px;
    }

    i {
      color: var(--gray-60);
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 10px 12px;
    margin-right: 8px;
    margin-bottom: 6px;
    background: var(--black);
    color: var(--white);
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
  }

  &__counter {
    position: absolute;
    width: 20px;
    height: 20px;
    min-width: 20px;
    top: -6px;
    right: -6px;
    z-index: 1;
    background: var(--black);
    color: var(--white);
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-container {
    width: 16px;
    height: 16px;
    min-width: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-100);
    cursor: pointer;
    margin-left: 8px;
  }

  &__clear-all {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: underline;
  }
}
