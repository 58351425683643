.widget {
  min-height: 330px;
  border: 1px solid var(--gray-30);
  border-radius: 12px;

  &--biggest-height {

    .widget__body {
      max-height: 320px;
      height: 320px;
    }
  }

  &--xl {

    .widget__body {
      max-height: 408px;
      height: 408px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--gray-30);
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 8px;
    border-radius: 6px;
    background-color: var(--gray-10);
  }

  &__title {
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    white-space: nowrap;
  }

  &__body {
    position: relative;
    padding: 0;
    max-height: 266px;
    height: 266px;

    &--mid-height {
      max-height: 456px;
      height: 456px;

      .widget__chart {
        max-height: 456px;
        height: 456px;
      }
    }

    &--max-height {
      max-height: 606px;
      height: 606px;

      .widget__chart {
        max-height: 606px;
        height: 606px;
      }
    }
  }

  div[echarts] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__chart {
    max-height: 266px;
    height: 266px;
    width: auto;
  }
}