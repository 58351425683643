.pagination {
  margin-bottom: 0!important;

  .page-item {
    &:not(:last-child) {
      margin-right: 10px;
    }

    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 8px;
      color: var(--black);
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0;
      border: none;
      box-shadow: none;
      &:hover {
        background-color: var(--gray-30);
      }
    }

    &.active {
      .page-link {
        color: var(--white);
        background-color: var(--black);
      }
    }

    &.disabled {
      opacity: 1;
      
      .page-link {
        color: var(--black);
        background: transparent;
      }
    }
  }

  &-first, &-last, &-next, &-prev {
    .page-link {
      font-size: 18px !important;
      font-weight: 600;
    }
  }
}
