.side-panel {
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 103;
  width: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(155, 162, 168, 0.25);
  transition: .6s ease-out;
  overflow: hidden;

  &--opened {
    right: 0;
  }
  &__header {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 24px;
    border-bottom: 1px solid var(--gray-20);
  }
  &__body {
    min-height: calc(100% - 176px);
    max-height: calc(100% - 176px);
    overflow-y: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
  &__loader {
    min-height: calc(100% - 146px);
    max-height: calc(100% - 146px);
    position: relative;
  }
  &__footer {
    height: 88px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
    border-top: 1px solid var(--gray-20);
  }
  &--modal-mode {
    position: fixed;
    right: 50%;
    transform: translateX(50%) ;
    margin-top: 100px;
    width: 800px;
    height: 80vh;
    border-radius: 16px;
    display: none;
    &.side-panel--opened {
      display: block;
    }
    .side-panel__header {
      height: auto;
      padding: 16px 24px
    }
    .side-panel__body {
      height: 100%;
      min-height: calc(100% - 146px);
      max-height: calc(100% - 146px);
    }
    .side-panel__footer {
      height: auto;
      padding: 16px 24px
    }
  }
}
.side-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  background-color: var(--gray-40);
  opacity: .5;
}
