.ap-accordion-ui {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 27px;
    padding: 24px;
    font-weight: 600;
    cursor: pointer;

    &--disabled {
      color: var(--gray-40);
      pointer-events: none;
    }

    i {
      transition: .2s;
    }

    &.ap-accordion-ui__header--left {
      justify-content: flex-start;
    }

    &:hover {
      background-color: var(--gray-10);
    }
  }

  &__body {
    overflow: hidden;
    height: 0;
    transition: all .2s;

    &--inner {
      display: flex;
      flex-direction: column;
      margin-top: -100%;
      transition: all .2s;
      padding: 0 24px;
    }
  }

  &--expanded {
    .ap-accordion-ui__header {

      i {
        transform: rotate(180deg);
        transition: .2s;
      }

      &.ap-accordion-ui__header--left {
        i {
          transform: rotate(90deg);
          transition: .2s;
        }
      }

      &:hover {
        background-color: var(--white);
      }
    }

    .ap-accordion-ui__body {
      height: 100%;

      .ap-accordion-ui__body--inner {
        margin-top: 0;
      }
    }
  }

  &--disabled {
    .ap-accordion-ui__header {
      color: var(--gray-40);
      pointer-events: none;
    }
  }
}
