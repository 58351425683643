.datepicker {
  position: relative;
  cursor: pointer;

  &--dashboard {
    top: 100px;
  }

  &--dashboard-container {

    input {
      width: 100%;
      height: 100%;
      padding: 0 16px 0 42px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0;
      color: var(--black);
      background-color: var(--gray-20);
      border-radius: 8px;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      content: '';
      width: 24px;
      height: 24px;
      background: url('/assets/images/icons/datepicker/calendar.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &--ghost {

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: 24px;
      height: 24px;
      background: url('/assets/images/icons/datepicker/calendar.svg') center center no-repeat;
      background-size: contain;
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0 16px 0 32px;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }

  &--inline {
    .bs-datepicker {
      box-shadow: none;

      .bs-datepicker-container {
        padding: 0;
      }

      .bs-datepicker-body table td {
        color: var(--black);
      }

      .bs-datepicker-body table td span {
        font-size: 12px;
      }

      .bs-datepicker-body table td span.selected {
        background: var(--black);
      }

      .bs-datepicker-body table td span.in-range {
        &:before {
          top: 0;
          left: 0;
          right: auto;
          bottom: auto;
          width: 100%;
          height: 100%;
          background-color: var(--gray-30) !important;
        }
        &:hover {
          .bs-datepicker-body table td span.in-range:before {
            background-color: var(--gray-40);
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }
      }

      .bs-datepicker-body table td span.in-range:hover {
        .bs-datepicker-body table td span.in-range:before {
          background-color: var(--gray-40);
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }

      .bs-datepicker-body table td span.select-start {
        &:before {
          top: 0;
          left: 0;
          right: auto;
          bottom: auto;
          width: 100%;
          height: 100%;
          background-color: var(--gray-30);
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
        }
      }

      .bs-datepicker-body table td span.select-end {
        &:before {
          top: 0;
          left: 0;
          right: auto;
          bottom: auto;
          width: 100%;
          height: 100%;
          background-color: var(--gray-30);
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }

      .bs-datepicker-body,
      .bs-datepicker-head {
        min-width: 224px;
        width: 224px
      }

      .bs-datepicker-body {
        padding: 0;
        border: none;

        table {

          
          th {
            padding: 8px 0;
            color: var(--gray-80);
          }

          td {
            width: 30px !important;
            max-width: 30px !important;

            span {
              margin: 0;

              &.disabled {
                color: var(--gray-40);
              }
            }
          }
        }
      }

      .bs-datepicker-head {
        background: transparent;

        * {
          color: var(--black);
        }
      }

      bs-days-calendar-view {
        padding: 12px;
      }
    }
  }
}


bs-daterangepicker-inline {
  bs-daterangepicker-inline-container {
    border: 1px solid var(--gray-30);
    border-radius: 12px;
    overflow: hidden;

    .bs-datepicker-head {
      display: flex;
      justify-content: center;

      &:after,
      &:before {
        display: none;
      }

      bs-datepicker-navigation-view {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.bs-datepicker.datepicker--single,
.bs-datepicker.datepicker--dashboard {

  .bs-datepicker-body table td span.selected {
    background: var(--black);
  }

  .bs-datepicker-head {
    display: flex;
    justify-content: center;

    &:after,
    &:before {
      display: none;
    }

    * {
      color: var(--black);
    }

    bs-datepicker-navigation-view {
      display: flex;
      justify-content: center;
    }
  }
}

.bs-datepicker.datepicker--pickup-booking {
  top: -2px;
  border-radius: 12px;
  border: 1px solid var(--gray-30);
  box-shadow: 0px 8px 8px -4px rgba(58, 62, 66, 0.03), 0px 20px 24px -4px rgba(58, 62, 66, 0.08);
  
  .bs-datepicker-container {
    padding: 12px;
  }

  .bs-datepicker-head {
    padding: 4px 0 0 0;
  }
  
  .bs-datepicker-body {
    padding: 0;
    border: none;
  }
}